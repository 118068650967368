@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Itim&family=Sriracha&display=swap');

* {
  font-family: 'Sriracha', cursive;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: url("./assets/images/new-bg.gif") no-repeat center center fixed;
  background-size: cover;
}



::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #313131;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #313131;
  border-radius: 15px;
}